<template>
  <div>
    <!-- 顶部开课了统计情况 -->
    <div style="background: #fff; border-radius: 6px; padding: 10px 10px 10px">
      <div class="boxTit">
        <div class="boxTitImgText">
          <img
            src="@/assets/startClassStatistics/icon_home_course.png"
            alt=""
          />开课率统计情况
        </div>
        <div>
          {{
            overviewStatisticsData.sysSchoolTermDTO.sysSchoolTermDescription
          }}（{{
            overviewStatisticsData.sysSchoolTermDTO.sysSchoolTermStartTime
              | dayFilters
          }}
          -
          {{
            overviewStatisticsData.sysSchoolTermDTO.sysSchoolTermEndTime
              | dayFilters
          }}
          ）
        </div>
        <div>
          <el-radio-group
            v-model="rangeType"
            @change="changRangeType"
            size="small"
          >
            <el-radio-button label="2">本周</el-radio-button>
            <el-radio-button label="3">本月</el-radio-button>
            <el-radio-button label="4">本学期</el-radio-button>
            <el-radio-button label="5">今年</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div
        style="
          width: 100%;
          border: 2px solid #0676ff;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
        "
      >
        <div class="topBoxListLeft">
          <div class="topBoxLi" style="margin-bottom: 10px">
            <div>本周总开课数（节）</div>
            <span style="font-size: 30px">{{
              overviewStatisticsData.weekOpenLessonCount
            }}</span>
            <div
              style="
                margin-top: 20px;
                font-size: 18px;
                color: #27393c;
                font-weight: normal;
              "
            >
              总对比上周 {{ overviewStatisticsData.lastWeekOpenLessonRate }}
              <img
                :src="
                  overviewStatisticsData.lastWeekOpenLessonRate[0] == '-'
                    ? '@/assets/startClassStatistics/icon_course_decline.png'
                    : '@/assets/startClassStatistics/icon_course_rise.png'
                "
                alt=""
              />
            </div>
          </div>
          <div class="topBoxLi topBoxLi2" style="padding-top: 40px">
            <div>本学期总开课数（节）</div>
            <span style="font-size: 30px; margin-top: 20px; display: block">{{
              overviewStatisticsData.yearTermOpenLessonCount
            }}</span>
            <!-- <div
              style="
                margin-top: 20px;
                font-size: 18px;
                color: #27393c;
                font-weight: normal;
              "
            >
              对比上学期 8%
              <img
                src="@/assets/startClassStatistics/icon_course_rise.png"
                alt=""
              />
            </div> -->
          </div>
        </div>
        <div>
          <span style="font-size: 18px; font-weight: bolder"
            >各校课程开展进度</span
          >
          <div id="SchoolDevEchartsID"></div>
        </div>
        <div style="width: 600px">
          <span style="font-size: 18px; font-weight: bolder"
            >各校课程开出概览</span
          >
          <div id="SchoolOpenEchartsID"></div>
        </div>
      </div>
    </div>

    <!-- 中-->
    <div style="display: flex; margin-top: 20px">
      <!-- <div style="background: #fff; border-radius: 6px; padding: 10px 10px 10px">
      
      </div> -->
      <div
        style="
          width: calc(50% - 10px);
          margin-right: 10px;
          background: #fff;
          border-radius: 6px;
          padding: 10px 10px 10px;
        "
      >
        <div class="boxTit">
          <div class="boxTitImgText">
            <img
              src="@/assets/startClassStatistics/icon_course_ranking.png"
            />课程开出排名
          </div>
          <div @click="moreBtn(1)" style="cursor: pointer; color: #999999">
            查看更多 >
          </div>
        </div>
        <!-- 表格 -->
        <div>
          <el-table
            :data="tableDataRank"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#F6F7F8',
            }"
            :cell-style="{ 'text-align': 'center' }"
            height="330px"
            border
            style="width: 100%"
          >
            <el-table-column width="70" label="排名">
              <template slot-scope="row">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    line-height: 30px;
                  "
                  v-html="setIndexStyle(row.$index + 1)"
                ></div>
              </template>
            </el-table-column>
            <el-table-column prop="sysOrgSchoolName" label="学校">
            </el-table-column>
            <el-table-column prop="openLessonRate" width="90" label="开出率">
            </el-table-column>
            <el-table-column prop="openLessonCount" width="90" label="开出数">
            </el-table-column>
            <el-table-column width="120" label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="detailBtn(scope.row)" size="mini"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div
        style="
          width: calc(50% - 10px);
          margin-left: 10px;
          background: #fff;
          border-radius: 6px;
          padding: 10px 10px 10px;
        "
      >
        <div class="boxTit">
          <div class="boxTitImgText">
            <img
              src="@/assets/startClassStatistics/icon_course_tendency.png"
            />课程开展趋势
          </div>
        </div>
        <div id="classTrendEchartsID"></div>
      </div>
    </div>
    <!-- 尾-->
    <div style="display: flex; margin-top: 20px">
      <div
        style="
          width: calc(50% - 10px);
          margin-right: 10px;
          background: #fff;
          border-radius: 6px;
          padding: 10px 10px 10px;
        "
      >
        <div class="boxTit">
          <div class="boxTitImgText">
            <img
              src="@/assets/startClassStatistics/icon_course_ranking.png"
            />待开展课程数
          </div>
          <div @click="moreBtn(3)" style="cursor: pointer; color: #999999">
            查看更多 >
          </div>
        </div>
        <!-- 表格 -->
        <div>
          <el-table
            :data="tableDataCourse"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#F6F7F8',
            }"
            :cell-style="{ 'text-align': 'center' }"
            height="330px"
            border
            style="width: 100%"
          >
            <el-table-column width="70" label="排名">
              <template slot-scope="row">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    line-height: 30px;
                  "
                  v-html="setIndexStyle(row.$index + 1)"
                ></div>
              </template>
            </el-table-column>
            <el-table-column prop="sysOrgSchoolName" label="学校">
            </el-table-column>
            <el-table-column
              prop="notOpenLessonCount"
              width="90"
              label="待开数"
            >
            </el-table-column>
            <el-table-column width="120" label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="detailBtn(scope.row)" size="mini"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div
        style="
          width: calc(50% - 10px);
          margin-left: 10px;
          background: #fff;
          border-radius: 6px;
          padding: 10px 10px 10px;
        "
      >
        <div class="boxTit">
          <div class="boxTitImgText">
            <img
              src="@/assets/startClassStatistics/icon_course_voucher.png"
            />课程开展凭证
          </div>
          <div @click="moreVoucherBtn" style="cursor: pointer; color: #999999">
            查看更多 >
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 330px;
            overflow-y: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          "
        >
          <div class="voucherBox" v-for="(item, i) in voucherList" :key="i">
            <div class="infoBox">
              <div
                class="infoBoxLeft"
                :style="
                  item.sysSubjectDic.sysDicName == '化学'
                    ? 'background: #ff7c06;'
                    : item.sysSubjectDic.sysDicName == '生物'
                    ? 'background: #00ab0a;'
                    : ''
                "
              >
                {{ item.sysSubjectDic.sysDicName }}
              </div>
              <div class="infoBoxRight">
                <div class="infoBoxName">{{ item.sysOrgSchoolName }}</div>
                <div class="infoBoxTime">
                  {{ item.schoolTime | dayFilters('YYYY年MM月DD日 HH:mm') }}
                </div>
              </div>
            </div>
            <div class="imgList">
              <img
                v-for="e in item.crsOpenVoucherList"
                :key="e"
                :src="this.$imageAddress(e)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="课程开展情况"
      :visible.sync="dialogVisible"
      width="1000px"
    >
      <!--  -->
      <el-row :gutter="20" class="examQuestionsManageTop">
        <el-col
          :span="8"
          v-for="(item, i) in this.$store.state.public_data.sysSubjectDicList"
          :key="item.name"
        >
          <div
            class="subjectBox"
            @click="clickBtn(i, item.value)"
            :class="checkedNum == i + 1 ? 'active' : ''"
            style="font-size: 20px"
          >
            {{ item.label }}考题
            <!-- <span>
              {{ item.subjectTotal }}
              <i>道</i>
            </span> -->
            <div class="iconBig">
              <img :src="require(`../../assets/${i + 1}.png`)" />
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-row>
        <el-table
          v-loading="dialogVisibleLoading"
          border
          ref="dialogVisibleTableData"
          :data="dialogVisibleTableData"
          tooltip-effect="dark"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
        >
          <el-table-column
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
          <el-table-column prop="sysSubjectDicId" label="科目">
            <template slot-scope="scope">
              {{ subjectTypeFilters(scope.row.sysSubjectDicId) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="resExperimentCourseName"
            label="课程名称"
          ></el-table-column>
          <el-table-column
            prop="sysOrgSchoolRoomName"
            label="上课地点"
          ></el-table-column>
          <el-table-column prop="schoolTime" label="上课日期">
            <template slot-scope="scope">
              {{ scope.row.schoolTime | dayFilters('YYYY-MM-DD') }}
            </template>
          </el-table-column>
          <el-table-column prop="sysSchoolClassSession" label="节次">
            <template slot-scope="scope">
              <span
                v-for="item in scope.row.sysSchoolClassSession"
                :key="item.sysSchoolClassSessionId"
                >{{ item.sysSchoolClassSessionName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="sysOrgSchoolClassName"
            label="上课班级"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="sysOrgSchoolClassStudentCount"
            label="班级人数"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="teacherName"
            label="任课老师"
            width="90"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.totalSize"
        ></el-pagination>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { throttle, debounce } from '@/utils/util'
import dayjs from 'dayjs'
import {
  getOpenPlanTendencyApi,
  getOpenSchoolStatisticsApi,
  getOpenPlanOverviewApi,
  getOpenPlanStatisticsRankingsPageApi,
  getOpenPlanStatisticsPageApi,
  getOpenPlanVoucherPageApi,
} from '@/api/courseStart/startClassStatistics.js'
export default {
  name: 'startClassStatistics',
  data() {
    return {
      // 范围类型(1日 2周 3月 4学期 5年度)
      rangeType: 4,
      // 开课率统计情况数据
      overviewStatisticsData: {
        lastWeekOpenLessonRate: '',
        sysSchoolTermDTO: {
          enabled: 0,
          sysSchoolTermCode: '',
          sysSchoolTermDescription: '',
          sysSchoolTermEndTime: 0,
          sysSchoolTermId: 0,
          sysSchoolTermName: '',
          sysSchoolTermStartTime: 0,
          sysSchoolTermType: 0,
          sysSchoolTermYear: '',
          sysSchoolTermYearDicId: 0,
          tenantId: 0,
        },
        weekOpenLessonCount: 0,
        yearTermOpenLessonCount: 0,
      },
      schoolDevEcharts: null,
      schoolOpenEcharts: null,
      // 排名
      tableDataRank: [],
      classTrendEcharts: null,
      // 待开展数
      tableDataCourse: [],
      // 课程开展凭证
      voucherList: [],

      // 查看详情
      dialogVisible: false,
      dialogVisibleLoading: false,
      dialogVisibleTableData: [],
      dialogVisibleParam: {
        sysOrgSchoolId: '',
        sysSubjectDicId: '',
      },
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      //选中
      checkedNum: 0,
    }
  },
  async created() {
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
    await this.$store.dispatch('sessionDic')
  },
  mounted() {
    document.querySelector('body').setAttribute('style', 'background:#F5F6FA')
    this.rangeType = 4
    // 课程开展趋势
    this.getOpenPlanTendencyApiFunc()
    // 各校课程开展进度&各校课程开出概览
    this.getOpenSchoolStatisticsApiFunc(4)
    // 开课率统计情况
    this.getOpenPlanOverviewApiFunc()
    //待开展课程数
    this.getTableDataRank()
    // 待开展课程数
    this.getTableDataCourse()
    this.getOpenPlanVoucherPageApiFunc()
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    // 切换rangeType
    changRangeType: debounce(function (key) {
      this.getOpenSchoolStatisticsApiFunc(this.rangeType)
      //待开展课程数
      this.getTableDataRank()
      // 待开展课程数
      this.getTableDataCourse()
      this.getOpenPlanVoucherPageApiFunc()
    }, 300),
    // 开课率统计情况
    getOpenPlanOverviewApiFunc() {
      getOpenPlanOverviewApi()
        .then((res) => {
          if (res.success) {
            this.overviewStatisticsData = res.data
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 各校课程开展进度&各校课程开出概览
    getOpenSchoolStatisticsApiFunc(row) {
      getOpenSchoolStatisticsApi(row)
        .then((res) => {
          if (res.success) {
            let data1 = [
              { value: res.data.realOpenLessonCount, name: '课程实际开出率' },
              {
                value: res.data.surplusOpenLessonCount,
                name: '课程计划剩余率',
              },
            ]
            let data2 = res.data.crsOpenPlanStatusticsSchoolCarryOutList.map(
              (e) => ({
                x: e.sysOrgSchoolName,
                y: e.crsOpenLessonCount,
              })
            )
            this.getSchoolOpenEchartsFunc(data1)
            this.getSchoolDevEchartsFunc(data2)
          } else {
          }
        })
        .catch((err) => {})
    },
    // 课程开展趋势
    getOpenPlanTendencyApiFunc() {
      getOpenPlanTendencyApi()
        .then((res) => {
          if (res.success) {
            let data = res.data.map((e) => ({
              x: dayjs(e.dateTime).format('MM/DD'),
              y: e.realOpenLessonCount,
            }))
            this.getClassTrendEchartsFunc(data)
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 课程开出排名
    async getTableDataRank() {
      let param = {
        rangeType: this.rangeType,
        sortFieldType: 1, // (1开课率 2开出数 3待开数)
        sortType: 2, // (1升序 2降序)
        // sysOrgSchoolId: 0,学校id
      }
      this.tableDataRank = await this.getOpenPlanStatisticsRankingsPageApiFunc(
        param,
        this.rangeType
      )
    },
    // 待开展课程数
    async getTableDataCourse() {
      let param = {
        rangeType: this.rangeType,
        sortFieldType: 3, // (1开课率 2开出数 3待开数)
        sortType: 2, // (1升序 2降序)
        // sysOrgSchoolId: 0,学校id
      }
      this.tableDataCourse =
        await this.getOpenPlanStatisticsRankingsPageApiFunc(
          param,
          this.rangeType
        )
    },
    // 课程开展排行榜
    async getOpenPlanStatisticsRankingsPageApiFunc(param, rangeType) {
      try {
        const res = await getOpenPlanStatisticsRankingsPageApi(
          1,
          16,
          param,
          rangeType
        )
        console.log('课程开展排行榜', res)
        if (res.success) {
          return res.data
        } else {
          this.$message.warning(res.msg)
          return []
        }
      } catch (error) {}
    },
    // 课程开展凭证
    getOpenPlanVoucherPageApiFunc() {
      getOpenPlanVoucherPageApi(1, 4, {}, this.rangeType)
        .then((res) => {
          if (res.success) {
            this.voucherList = res.data
          } else {
          }
        })
        .catch((err) => {})
    },
    // 查看详情
    detailBtn(row) {
      this.dialogVisibleParam.sysOrgSchoolId = row.sysOrgSchoolId
      this.changeConditionForm()
    },
    getOpenPlanStatisticsPageApiFunc(param) {
      this.dialogVisibleLoading = true
      getOpenPlanStatisticsPageApi(
        this.page.pageIndex,
        this.page.pageSize,
        param,
        this.rangeType
      )
        .then((res) => {
          this.dialogVisibleLoading = false
          if (res.success) {
            this.dialogVisibleTableData = res.data
            this.page.totalSize = res.total
          } else {
            this.$message.info(res.msg)
          }
          this.dialogVisible = true
        })
        .catch((err) => {})
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    // 切换
    clickBtn(i, subjectType) {
      if (this.checkedNum == i + 1) {
        this.checkedNum = 0
        this.dialogVisibleParam.sysSubjectDicId = undefined
      } else {
        this.checkedNum = i + 1
        this.dialogVisibleParam.sysSubjectDicId = subjectType
      }
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    changeConditionForm() {
      let conditionObj = JSON.parse(JSON.stringify(this.dialogVisibleParam))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.getOpenPlanStatisticsPageApiFunc(conditionObj)
    },
    // 查看更多
    moreBtn(key) {
      this.$router.push({
        path: '/courseStart/courseRanking',
        query: {
          rangeType: this.rangeType,
          sortFieldType: key, // (1开课率 2开出数 3待开数)
        },
      })
    },
    // 课程开展凭证查看更多
    moreVoucherBtn() {
      this.$router.push({
        path: '/courseStart/courseVoucher',
        query: {
          rangeType: this.rangeType,
        },
      })
    },

    // 各学校开展进度echarts
    getSchoolDevEchartsFunc(row) {
      this.schoolDevEcharts = echarts.init(
        document.getElementById('SchoolDevEchartsID')
      )
      let option = {
        tooltip: {
          trigger: 'axis', // 触发类型，坐标轴触发，适用于柱状图
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0, //使x轴文字显示全
            formatter: (params) => {
              let newParamsName = ''
              let paramsNameNumber = params.length
              let provideNumber = 4 //一行显示几个字
              let rowNumber = Math.ceil(paramsNameNumber / provideNumber)
              if (paramsNameNumber > provideNumber) {
                for (let p = 0; p < rowNumber; p++) {
                  let tempStr = ''
                  let start = p * provideNumber
                  let end = start + provideNumber
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber)
                  } else {
                    tempStr = params.substring(start, end) + '\n'
                  }
                  newParamsName += tempStr
                }
              } else {
                newParamsName = params
              }
              return newParamsName
            },
          },
          data: row.map((e) => e.x),
        },
        color: ['#0676FF'],
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '进度',
            data: row.map((e) => e.y),
            type: 'bar',
            label: {
              show: true,
              position: 'top',
            },
          },
        ],
      }
      this.schoolDevEcharts.setOption(option)
    },
    // 各校课程开出概览echarts
    getSchoolOpenEchartsFunc(row) {
      this.schoolOpenEcharts = echarts.init(
        document.getElementById('SchoolOpenEchartsID')
      )
      // let data1 = [
      //   { value: 650, name: '课程实际开出率' },
      //   { value: 350, name: '课程计划剩余率' },
      // ]
      let option = {
        title: {
          show: true,
          text: `{a|${row[0].value + row[1].value}}\n计划总量`,
          left: '28%',
          // left: 'center',
          top: '41%',
          fontSize: 35,
          textStyle: {
            align: 'center',
            rich: {
              a: {
                align: 'center',
                fontWeight: 'bold',
                fontSize: 35,
                text: 'center',
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          y: 'center',
          x: 'right',
          itemGap: 30,
          formatter: function (name) {
            // 获取legend显示内容
            let data = row //这个是展示的数据
            let total = 0
            let tarValue = 0
            let value = 0
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value
              if (data[i].name == name) {
                tarValue = data[i].value
                if (i == 0) {
                  value = `已开${data[i].value}节`
                } else {
                  value = `剩余${data[i].value}节`
                }
              }
            }
            let p = total == 0 ? 0 : ((tarValue / total) * 100).toFixed(1)
            return [
              '{a|' + name + '}',
              '\n',
              '\n',
              '{b|' + p + '%}',
              '\r \r{c|' + value + '}', //a、b、x、跟下面的rich对应
            ].join(' ')
          },
          textStyle: {
            align: 'center',
            rich: {
              a: {
                fontSize: 18,
              },
              b: {
                fontSize: 18,
                fontWeight: 'bold',
              },
              c: {
                fontSize: 14,
              },
            },
          },
        },
        color: ['#586FF5', '#3ECBF4'],
        series: [
          {
            type: 'pie',
            radius: ['38%', '70%'],
            center: ['35%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },
            data: row,
          },
        ],
      }
      this.schoolOpenEcharts.setOption(option)
    },
    getClassTrendEchartsFunc(row) {
      this.classTrendEcharts = echarts.init(
        document.getElementById('classTrendEchartsID')
      )
      let option = {
        color: ['#1981FF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#1981FF',
            },
          },
        },
        grid: {
          left: '0%',
          right: '2%',
          bottom: '0%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: row.map((e) => e.x),
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '开出数',
            type: 'line',
            stack: 'Total',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#99C7FF',
                },
                {
                  offset: 1,
                  color: '#EFF6FF',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: row.map((e) => e.y),
          },
        ],
      }

      this.classTrendEcharts.setOption(option)
    },
    // 排名样式
    setIndexStyle(key) {
      switch (key) {
        case 1:
          return `<img src="${require('@/assets/startClassStatistics/icon_ranking_first.png')}">`
        case 2:
          return `<img src="${require('@/assets/startClassStatistics/icon_ranking_second.png')}">`
        case 3:
          return `<img src="${require('@/assets/startClassStatistics/icon_ranking_third.png')}">`
        default:
          return `<span
          style="display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          height: 30px;
          width: 30px;
          line-height: 30px;
          background: #DADCDF;
          ">${key}</span>`
      }
    },
    sysSemesterTermDicFilters(key) {
      return this.$store.state.public_data.sysSemesterTermDicList.find(
        (e) => e.value === key
      )?.label
    },
    subjectTypeFilters(key) {
      return this.$store.state.public_data.sysSubjectDicList.find(
        (e) => e.value === key
      )?.label
    },
    sysSemesterTermGradeDicListFilters(key) {
      const item =
        this.$store.state.public_data.sysSemesterTermGradeDicList.find((e) =>
          e.children.some((x) => x.value === key)
        )
      return item ? item.children.find((e) => e.value === key)?.label : null
    },
  },
  watch: {},
  filters: {
    dayFilters(val, format = 'YYYY-MM-DD') {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format(format) : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.boxTit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f9f9f9;
  box-sizing: border-box;
}
.boxTitImgText {
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    margin: 0;
  }
  div {
    height: 22px;
    line-height: 22px;
  }
}
.topBoxListLeft {
  .topBoxLi {
    font-size: 20px;
    box-sizing: border-box;
    font-weight: bolder;
    padding: 30px;
    width: 366px;
    height: 176px;
    background: url(../../assets/startClassStatistics/icon_course_week_bg.png)
      no-repeat center;
    background-size: 100% 100%;
  }
  .topBoxLi2 {
    background: url(../../assets/startClassStatistics/icon_course_semester_bg.png)
      no-repeat center;
  }
}
#SchoolDevEchartsID {
  width: 500px;
  height: 360px;
}
#SchoolOpenEchartsID {
  width: 600px;
  height: 360px;
}
#classTrendEchartsID {
  height: 330px;
  width: 100%;
}

.voucherBox {
  background: #f5f6fa;
  width: calc(50% - 5px);
  height: 150px;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  .infoBox {
    height: 60px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .infoBoxLeft {
      height: 46px;
      width: 46px;
      background: #0676ff;
      margin-right: 10px;
      line-height: 46px;
      font-size: 20px;
      text-align: center;
      border-radius: 16px;
      color: #fff;
    }
    .infoBoxRight {
      .infoBoxName {
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
        height: 30px;
      }
      .infoBoxTime {
        font-size: 16px;
        line-height: 30px;
        height: 30px;
      }
    }
  }
  .imgList {
    height: 60px;
    width: 100%;
    overflow-y: hidden;
    vertical-align: middle;
    overflow-x: auto;
    margin: 0;
    img {
      border-radius: 4px;
      overflow: hidden;
      width: auto;
      height: 60px;
      margin-right: 5px;
    }
  }
}

.examQuestionsManageTop {
  border-bottom: 1px solid #c8dbec;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.subjectBox {
  width: 100%;
  box-sizing: border-box;
  background: rgb(248, 248, 248);
  border: 2px solid #09c0c800;
  cursor: pointer;
  height: 70px;
  color: rgb(102, 102, 102);
  font-size: 14px;
  padding: 6px;
  padding-left: 66px;
  position: relative;

  .iconBig {
    // background-image: linear-gradient(to bottom, #2885da, #6eb7fc);
    width: 52px;
    height: 52px;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    // i {
    //   font-size: 30px;
    //   color: white;
    // }
    img {
      width: 100%;
      height: 100%;
    }
  }

  span {
    display: block;
    color: #409eff;
    font-size: 26px;

    i {
      font-style: normal;
      font-size: 14px;
      color: rgb(102, 102, 102);
    }
  }

  &:hover {
    background: rgb(213, 213, 213);
  }

  &.active {
    border: 2px solid #409eff;
  }
}
</style>
